export default {
  proxy_path: "https://9y0ujjfpt5.execute-api.us-east-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://80txy9b1zk.execute-api.us-east-2.amazonaws.com/api",
  google_api_key: "AIzaSyAVCuQtOaN6jrjHl89-Gi6FS2MKXHMOIwE",
  main_company_term_id: "6",
  crm_base_url: "staging.vale.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.vale.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.staging.vale.forwoodsafety.com",
    APP_CLIENT_ID: "5c9q9tpa3t84bes3qukqj72lag",
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://4fzrj98vwl.execute-api.us-east-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.staging.vale.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
